import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router";
import { useOrganizationId } from "~/hooks/organizationId";
import { useUser } from "~/hooks/user";
import { cn } from "~/lib/utils";

export function NotificationBadge({
  count,
  className = "",
}: {
  count: number;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "absolute -mr-10 -mt-7 grid h-5 min-w-5 place-items-center rounded-full bg-attention px-1",
        className,
      )}
    >
      <span className="text-xs font-bold leading-none text-white">{count}</span>
    </div>
  );
}

export function HomeNavTabbar() {
  const { pathname } = useLocation();
  const { latestOrganizationId } = useOrganizationId();
  const self = useUser();
  const [path, setPath] = useState(pathname);

  useEffect(() => {
    setPath(pathname);
  }, [pathname]);

  const tabs = [
    {
      name: "home",
      label: "コミュニティ",
      to: latestOrganizationId
        ? `/community/${latestOrganizationId}`
        : "/community",
      badge: 0,
    },
    {
      name: "map",
      label: "マップ",
      to: latestOrganizationId
        ? `/community/map?orgId=${latestOrganizationId}`
        : "/community/map",
      badge: 0,
    },
    {
      name: "schedule",
      label: "イベント予定",
      to: "/my/schedule",
      badge: 0,
    },
    {
      name: "sharing",
      label: "シェア履歴",
      to: "/my/sharing",
      badge: self?.unreadCount.sharings || 0,
    },
    // {
    //   name: "message",
    //   label: "メッセージ",
    //   to: "/my/message",
    //   badge: self?.unreadCount.messages || 0,
    // },
    {
      name: "activity",
      label: "通知",
      to: "/my/activity",
      badge: self?.unreadCount.notifications || 0,
    },
  ];

  return (
    <div id="home-nav-tabbar" className="grid h-14 grid-cols-5">
      {tabs.map(({ name, label, to, badge }, i) => {
        const isActive = tabs.find((tab) =>
          path.includes(tab.to.replace(/\?.*/, "")),
        )
          ? path.includes(to.replace(/\?.*/, ""))
          : i === 0;

        return (
          <Link
            key={`${name}-${isActive ? "active" : "inactive"}`}
            className={cn(
              "relative flex h-full flex-col items-center justify-center",
            )}
            to={to}
            onClick={() => setPath(to)}
            replace
          >
            <div className="flex h-7 items-center justify-center">
              <img
                src={`/common/icon-${name}${isActive ? "-active" : ""}.svg`}
                width="24"
                height="24"
                alt=""
              />
            </div>
            <span
              className={cn(
                "mt-0.5 line-clamp-1 text-[10px] font-bold",
                isActive ? "text-primary" : "text-gray-4",
              )}
            >
              {label}
            </span>

            {/* Preload images */}
            <img
              className="hidden"
              src={`/common/icon-${name}-active.svg`}
              alt=""
            />
            <img className="hidden" src={`/common/icon-${name}.svg`} alt="" />

            {badge > 0 && <NotificationBadge count={badge} />}
          </Link>
        );
      })}
    </div>
  );
}
