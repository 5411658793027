import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router";
import { useOrganizationId } from "~/hooks/organizationId";
import { cn } from "~/lib/utils";

const tabs = [
  {
    name: "new",
    label: "新着情報",
    to: "",
    width: 45,
    height: 36,
  },
  {
    name: "event",
    label: "交流イベント",
    to: "/event",
    width: 33,
    height: 32,
  },
  {
    name: "sharing",
    label: "シェアリング",
    to: "/sharing",
    width: 32,
    height: 32,
  },
  {
    name: "group",
    label: "グループ",
    to: "/group",
    width: 33,
    height: 32,
  },
  {
    name: "partner",
    label: "地域パートナー",
    to: "/partner",
    width: 32,
    height: 23,
  },
];

export function HomeTabbar() {
  const { pathname } = useLocation();
  const { organizationId } = useOrganizationId();
  const [path, setPath] = useState(pathname);
  const base = organizationId ? `/community/${organizationId}` : "/community";

  useEffect(() => {
    setPath(pathname);
  }, [pathname]);

  return (
    <div className="grid h-16 grid-cols-5 border-b-4 border-primary">
      {tabs.map(({ name, label, to, width, height }) => {
        const link = `${base}${to}`;
        const isActive = path === link;

        return (
          <Link
            key={`${name}-${isActive ? "active" : "inactive"}`}
            className={cn(
              "flex h-full flex-col items-center justify-center rounded-t-lg active:!rounded-b-none",
              isActive ? "!bg-primary" : "bg-background",
            )}
            to={link}
            onClick={() => setPath(link)}
            replace
          >
            <div className="mt-1 flex h-7 items-center justify-center">
              <img
                src={`/common/icon-${name}${isActive ? "-white" : ""}.svg`}
                width={width}
                height={height}
                alt=""
              />
            </div>
            <span
              className={cn(
                "mt-1 line-clamp-1 text-[10px] font-bold",
                isActive ? "text-white" : "text-gray-4",
              )}
            >
              {label}
            </span>

            {/* Preload images */}
            <img
              className="hidden"
              src={`/common/icon-${name}-white.svg`}
              alt=""
            />
            <img className="hidden" src={`/common/icon-${name}.svg`} alt="" />
          </Link>
        );
      })}
    </div>
  );
}
