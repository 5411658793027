import { Check, ChevronDown } from "lucide-react";
import { Link, useNavigate } from "react-router";
import { Button } from "~/components/ui/button";
import {
  Dialog,
  // DialogAction,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "~/components/ui/dialog";
import { DrawerClose } from "~/components/ui/drawer";
import { imgWithParams } from "~/lib/utils";
import { useOrganization } from "~/routes/community.$id";

export function CommunitySwitcher() {
  const { organization, joinedOrgs } = useOrganization();
  const navigate = useNavigate();

  return organization ? (
    <Dialog>
      <DialogTrigger className="rounded px-1">
        <div className="flex h-10 items-center justify-center">
          <div className="size-8 overflow-hidden rounded bg-gray-2">
            <img
              src={
                organization.images.length > 1
                  ? imgWithParams(organization.images[1], { w: 64, h: 64 })
                  : "/common/fallback-default.png"
              }
              width={32}
              height={32}
              alt=""
            />
          </div>
          <ChevronDown className="ml-1 size-4 text-primary" strokeWidth={3} />
        </div>
      </DialogTrigger>
      <DialogContent className="overflow-hidden p-0">
        <DialogHeader>
          <DialogTitle className="text-base font-normal">
            <div className="border-b p-3 text-center font-bold">
              コミュニティの切り替え
            </div>
          </DialogTitle>
          <DialogDescription asChild>
            <div className="!m-0">
              <div className="max-h-[calc(100svh_-_180px)] overflow-y-auto text-left">
                <div className="grid grid-cols-1 gap-3 p-3 text-foreground">
                  <DrawerClose className="block w-full text-left">
                    <div className="flex items-center text-primary">
                      <img
                        className="rounded"
                        src={
                          organization.images.length > 1
                            ? imgWithParams(organization.images[1], {
                                w: 64,
                                h: 64,
                              })
                            : "/common/fallback-default.png"
                        }
                        width="64"
                        height="64"
                        alt=""
                      />
                      <p className="ml-4 flex-1 font-bold">
                        {organization.name}
                      </p>
                      <Check />
                    </div>
                  </DrawerClose>

                  {joinedOrgs.length > 0 &&
                    joinedOrgs.map((org) => (
                      <DrawerClose
                        key={org.id}
                        className="text-left"
                        onClick={() => {
                          setTimeout(() => {
                            navigate(`/community/${org.id}`, { replace: true });
                          }, 200);
                        }}
                      >
                        <div className="flex items-center">
                          <img
                            className="rounded"
                            src={
                              org.images.length > 1
                                ? imgWithParams(org.images[1], {
                                    w: 64,
                                    h: 64,
                                  })
                                : "/common/fallback-default.png"
                            }
                            width="64"
                            height="64"
                            alt=""
                          />
                          <p className="ml-4 flex-1">{org.name}</p>
                        </div>
                      </DrawerClose>
                    ))}
                </div>

                <div className="mx-3 mb-3 mt-1 text-foreground">
                  <Link to="/community/all">
                    <Button className="w-full" variant="outline">
                      別のコミュニティに参加する
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  ) : null;
}
