import { Mail, Search } from "lucide-react";
import { Link, useLocation } from "react-router";
import { useNativeApp } from "~/hooks/nativeApp";
import { useOrganizationId } from "~/hooks/organizationId";
import { useUser } from "~/hooks/user";
import { cn } from "~/lib/utils";
import { CommunitySwitcher } from "../parts/communitySwitcher";
import { HomeNavTabbar, NotificationBadge } from "../parts/homeNavTabbar";
import { HomeTabbar } from "../parts/homeTabbar";
import { Navbar } from "../parts/navbar";
import { Button } from "../ui/button";

export function AppLayout({
  children,
  title = "",
  backTo,
  backToClose = false,
  className = "",
  navbarRightItems = [],
  homeNavTabbar = false,
  homeTabbar = false,
}: {
  children: React.ReactNode;
  title?: string;
  backTo?: string | boolean;
  backToClose?: boolean;
  className?: string;
  navbarRightItems?: React.ReactNode[];
  homeNavTabbar?: boolean;
  homeTabbar?: boolean;
}) {
  const { organizationId } = useOrganizationId();
  const self = useUser();
  const { safeAreaInsets } = useNativeApp();
  const navBarHeight = title ? 48 : 0;
  const homeTabbarHeight = homeTabbar ? 68 : 0;
  const homeNavTabbarHeight = homeNavTabbar ? 56 : 0;

  const location = useLocation();
  const communityTop =
    /^\/community\/[0-9]+\/?(event|sharing|group|partner)?$/.test(
      location.pathname,
    );

  const unreadMessageCount = self?.unreadCount.messages || 0;

  return (
    <>
      {title && (
        <div
          className="no-print max-container fixed top-0 z-50 border-b bg-background"
          style={{
            paddingTop: safeAreaInsets.top,
            height: navBarHeight + safeAreaInsets.top,
          }}
        >
          <Navbar
            title={title}
            backTo={backTo}
            backToClose={backToClose}
            rightItems={[
              ...navbarRightItems,
              !!organizationId && homeTabbar && (
                <Link to={`/community/${organizationId}/search`}>
                  <Button
                    className="px-2 text-gray-4 hover:no-underline"
                    variant="link"
                    size="sm"
                    tabIndex={-1}
                  >
                    <Search />
                  </Button>
                </Link>
              ),
              !!organizationId && homeTabbar && (
                <Link to="/my/message" className="relative">
                  <Button
                    className="px-2 text-gray-4 hover:no-underline"
                    variant="link"
                    size="sm"
                    tabIndex={-1}
                  >
                    <Mail />
                  </Button>

                  {unreadMessageCount > 0 && (
                    <NotificationBadge
                      count={unreadMessageCount}
                      className="-mt-9 ml-6"
                    />
                  )}
                </Link>
              ),
              !!organizationId && communityTop && <CommunitySwitcher />,
            ].filter((val) => !!val)}
          />
        </div>
      )}

      {homeTabbar && (
        <div
          className="max-container fixed z-50 h-16 bg-app-background pt-1"
          style={{ top: navBarHeight + safeAreaInsets.top }}
        >
          <HomeTabbar />
        </div>
      )}

      {/* Safe area container */}
      <div
        className="flex w-full flex-1 bg-app-background"
        style={{
          paddingTop: navBarHeight + homeTabbarHeight + safeAreaInsets.top,
          paddingBottom: homeNavTabbarHeight + safeAreaInsets.bottom,
        }}
      >
        <div className={cn("w-full flex-1 pb-12", className)}>{children}</div>
      </div>

      {homeNavTabbar && (
        <>
          <div
            className="max-container fixed bottom-0 z-50 border-t bg-background"
            style={{
              paddingBottom: safeAreaInsets.bottom,
              height: homeNavTabbarHeight + safeAreaInsets.bottom,
            }}
          >
            <HomeNavTabbar />
          </div>
        </>
      )}
    </>
  );
}
