import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { Cog, Contact, ExternalLink, SlidersHorizontal } from "lucide-react";
import { Link } from "react-router";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "~/components/ui/collapsible";
import { Separator } from "~/components/ui/separator";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "~/components/ui/sheet";
import { useOrganizationId } from "~/hooks/organizationId";
import { useUser } from "~/hooks/user";
import { DropdownWeglotSwitcher } from "~/hooks/weglot";
import { isConcierge } from "~/lib/utils";
import { Avatar, AvatarImage } from "../ui/avatar";

export function Sidebar() {
  const user = useUser();
  const { latestOrganizationId } = useOrganizationId();

  const canAccessAdmin =
    user?.roles &&
    (user.roles.includes("admin") ||
      user.roles.includes("staff") ||
      user.roles.includes("accountant"));

  return (
    <Sheet>
      <SheetTrigger className="rounded">
        <div className="flex h-10 w-14 items-center justify-center">
          <img
            src="/common/icon-navbar-menu.svg"
            width="24"
            height="24"
            alt=""
          />
        </div>
      </SheetTrigger>

      <SheetContent side="left">
        <VisuallyHidden>
          <SheetHeader>
            <SheetTitle></SheetTitle>
            <SheetDescription></SheetDescription>
          </SheetHeader>
        </VisuallyHidden>

        {user && (
          <div className="pt-4">
            <SheetClose asChild>
              <Link className="flex items-center py-2" to="/my/settings">
                <Avatar concierge={isConcierge(user.roles)}>
                  <AvatarImage src={user.avatar} />
                </Avatar>
                <span className="no-translate ml-4 truncate font-bold">
                  {user.name}
                </span>
              </Link>
            </SheetClose>

            <Separator className="my-2" />
          </div>
        )}

        {user && latestOrganizationId && (
          <>
            <ul className="font-bold">
              <li>
                <SheetClose asChild>
                  <Link
                    className="flex items-center py-3.5"
                    to={`/community/${latestOrganizationId}`}
                  >
                    <img
                      src="/common/icon-home-active.svg"
                      width="25"
                      height="24"
                      alt=""
                    />
                    <span className="ml-4">コミュニティトップ</span>
                  </Link>
                </SheetClose>
              </li>

              <li>
                <SheetClose asChild>
                  <Link
                    className="flex items-center py-3.5"
                    to={`/community/${latestOrganizationId}/profile/${user.id}`}
                  >
                    <Contact className="size-6 text-primary" />
                    <span className="ml-4">プロフィール</span>
                  </Link>
                </SheetClose>
              </li>

              <li>
                <SheetClose asChild>
                  <Link
                    className="flex items-center py-3.5"
                    to={`/community/${latestOrganizationId}/search`}
                  >
                    <img
                      src="/common/icon-user-search.svg"
                      width="24"
                      height="24"
                      alt=""
                    />
                    <span className="ml-4">ユーザー検索</span>
                  </Link>
                </SheetClose>
              </li>
            </ul>
            <Separator className="my-2" />
          </>
        )}

        <ul className="font-bold">
          <li>
            <SheetClose asChild>
              <Link
                className="flex items-center py-3.5"
                to={`/community/map${latestOrganizationId ? "?orgId=" + latestOrganizationId : ""}`}
              >
                <img
                  src="/common/icon-map-pin.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span className="ml-4">ユーザー分布マップ</span>
              </Link>
            </SheetClose>
          </li>
          <li>
            <SheetClose asChild>
              <a
                className="flex items-center py-3.5"
                href="https://asmama.jp/my-commu-guide/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/common/icon-beginner.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span className="ml-4">マイコミュの歩き方</span>
                <ExternalLink className="ml-2 size-4" />
              </a>
            </SheetClose>
          </li>
          <li>
            <a
              className="flex items-center py-3.5"
              href="https://asmama.jp/my-commu/question.html"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/common/icon-question.svg"
                width="24"
                height="24"
                alt=""
              />
              <span className="ml-4">よくある質問</span>
              <ExternalLink className="ml-2 size-4" />
            </a>
          </li>
          <li>
            <SheetClose asChild>
              <Link className="flex items-center py-3.5" to="/info/contact">
                <img
                  src="/common/icon-bubble-faq.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span className="ml-4">相談窓口</span>
              </Link>
            </SheetClose>
          </li>
          {canAccessAdmin && (
            <li>
              <SheetClose asChild>
                <Link className="flex items-center py-3.5" to="/admin">
                  <SlidersHorizontal className="size-6 text-primary" />
                  <span className="ml-4">マイコミュ管理画面</span>
                </Link>
              </SheetClose>
            </li>
          )}
          <li>
            <Collapsible>
              <CollapsibleTrigger className="flex w-full items-center py-3.5">
                <img
                  src="/common/icon-3dot.svg"
                  width="24"
                  height="24"
                  alt=""
                />
                <span className="ml-4">その他</span>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <ul className="ml-10">
                  <li>
                    <a
                      className="flex items-center py-2"
                      href="https://asmama.jp/my-commu/safety.html"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>安全に対する取り組み</span>
                      <ExternalLink className="ml-2 size-4" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="flex items-center py-2"
                      href="https://asmama.jp/cms/wp-content/uploads/2023/09/emergency2309_%E6%9C%80%E7%B5%82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>事故発生時マニュアル</span>
                      <ExternalLink className="ml-2 size-4" />
                    </a>
                  </li>
                  <li>
                    <Link className="flex py-2" to="/info/tou">
                      <span>会員規約</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="flex py-2" to="/info/tos">
                      <span>利用規約</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="flex py-2" to="/info/asct">
                      <span>特定商取引法に基づく表記</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="flex py-2" to="/info/privacy">
                      <span>プライバシーポリシー</span>
                    </Link>
                  </li>
                </ul>
              </CollapsibleContent>
            </Collapsible>
          </li>
        </ul>

        <Separator className="my-2" />

        <ul className="font-bold">
          <li>
            <SheetClose asChild>
              <Link className="flex items-center py-3.5" to="/my/settings">
                <Cog className="size-6 text-primary" />
                <span className="ml-4">アカウント設定</span>
              </Link>
            </SheetClose>
          </li>
        </ul>

        <Separator className="my-2" />

        <DropdownWeglotSwitcher className="mt-6" />
      </SheetContent>
    </Sheet>
  );
}
